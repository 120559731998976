<template>
    <div>
        <div class="navbar">
            <div class="logo" @click="$router.push({path: '/'})">
                <img :src="newLogo" alt />
<!--                <img :src="logoColor === 'black' ? logoBlack : logoWhite" alt />-->
            </div>
            <ul class="navs">
                <li
                    v-for="(item, index) in navs"
                    :key="index"
                    
                >
                    <span :class="currentPath === index ? 'actived' : null"
                          @click="jumpTo(item)">
                        {{item.cnName}}
                    </span>
                    <!--<router-link :to="item.templateUrl">
                        {{item.cnName}}
                        <div class="subNavs" v-if="item.children.length > 0">
                            <router-link
                                v-for="(subItem, subIndex) in item.children"
                                :key="subIndex"
                                :to="subItem.templateUrl"
                            >
                                <span>{{ subItem.cnName }}</span>
                            </router-link>
                        </div>
                    </router-link>-->
                </li>
            </ul>
            <div class="nav-other">
                <button class="login" @click="login" v-if="!username">登录</button>
                <button class="register" @click="register" v-if="!username">免费注册</button>
                <router-link to="/userCenter" v-if="username">{{ username }}</router-link>
                <button class="btn-logout" @click="logout" v-if="username">退出</button>
                <div class="icons">
                    <router-link to="/"></router-link>
                </div>
            </div>
        </div>

        <div class = "navH5">
            <ComponentMenuIndexH5 :navs = "navs" :userStyle = "logoColor === 'black' ? 'black' : ''"/>
            <div class="logo" @click="$router.push({path: '/'})">
                <img :src="logoColor === 'black' ? logoBlack : logoWhite" alt />
            </div>
        </div>
    </div>
</template>

<script>
    import {urls, banbaoUrl} from '@/lib/common'
    import ComponentMenuIndexH5 from "@/components/ComponentMenuIndexH5.vue";
    export default {
        components: {
          ComponentMenuIndexH5
        },
        props: {
            logoColor: {
                type: String,
                default: 'black'
            }
        },
        data() {
            return {
                banbaoUrl,
                newLogo: require('@/assets/images/education/logo-new.png'),
                logoBlack: require('@/assets/images/education/logo.png'),
                logoWhite: require('@/assets/images/education/logo_bottom.png'),
                navs: [],
                username: '',
                currentPath: ''

            }
        },
        methods: {
            login() {
                this.$router.push({
                    path: '/login',
                    query: {
                        "editorType": 0
                    }
                })
            },
            register() {
                this.$router.push({
                    path: '/login',
                    query: {
                        "editorType": 1
                    }
                })
            },
            getTopbarNavs() {
                this.$http.defaults.headers.get['Web-Site'] = 2;
                this.$http.get(urls.getTopbarNavs).then(res => {
                    if (res.data.status === 200) {
                        this.navs = res.data.data;
                        for (var i = 0; i < this.navs.length; i++) {
                            if (this.navs[i].templateUrl.indexOf('/online') !== -1) {
                                if (this.$route.path === this.navs[i].templateUrl) {
                                    this.currentPath = i;
                                    break
                                } else if (this.$route.path.indexOf('works') !== -1 || this.$route.path.indexOf('courses') !== -1) {
                                    this.currentPath = 5;
                                    break
                                }
                            } else if (this.navs[i].templateUrl.indexOf(this.$route.path) !== -1) {
                                this.currentPath = i;
                                break
                            }
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            logout() { // 退出登录
                const url = urls.logout;
                this.$cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});

                this.$cookies.remove('app.token', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.username', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.nickname', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.email', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.uid', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.isTeacher', {domain: banbaoUrl, path: ''});
                this.$cookies.remove('app.roles', {domain: banbaoUrl, path: ''});
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userinfo');
                this.$http.post(url).then(res => {
                    console.log(res)
                    if (res.data.status !== 200) {
                        this.$message.error(res.data.message);
                    } else {
                        this.$message.success("已退出登录.");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login"
                            })
                        }, 2000)
                    }
                })
            },
            jumpTo(item) {
                if (item.type === 2) {
                    window.open(item.templateUrl);
                } else if (this.$router.currentRoute.path.indexOf('/online/list/') !== -1 && this.$router.currentRoute.path.indexOf('textbook') !== -1 && item.templateUrl.indexOf('/online/list/') !== -1 && item.templateUrl.indexOf('textbook') !== -1) {
                    this.$store.commit('changeRefreshStatus', true);
                    setTimeout(() => {
                        this.$store.commit('changeRefreshStatus', false);
                    }, 1000)
                } else {
                    this.$router.push({
                        path: item.templateUrl
                    })
                }
            }
        },
        watch: {
            "$route.path": function(val) {
                for (var i = 0; i < this.navs.length; i++) {
                    if (this.navs[i].templateUrl.indexOf('/online') !== -1) {
                        if (val === this.navs[i].templateUrl) {
                            this.currentPath = i;
                            break
                        } else if (val.indexOf('works') !== -1 || val.indexOf('courses') !== -1) {
                            this.currentPath = 5;
                            break
                        }
                    } else if (this.navs[i].templateUrl.indexOf(val) !== -1) {
                        this.currentPath = i;
                        break
                    }
                }
            }
        },
        created() {
            const userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
            // const nickname = this.$cookies.get('app.nickname', {domain: banbaoUrl, path: ''});
            if (userinfo) {
                this.username = userinfo.realName;
            }
            // console.log(this.$route)
            this.getTopbarNavs()
        }
    };
</script>

<style lang="less" scoped>
    .navbar {
        width: 100%;
        min-width: 1380px;
        /*background: #009CEB;*/
        margin: auto;
        padding: 0 12.5% 2%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        z-index: 999;
        position:absolute;
        left: 0;
        top: 0;
        height: 80px;
        .logo {
            cursor: pointer;
            flex: 1.5;
            margin-right: 2vw;
            margin-top: 8px;
        }
        .navs {
            flex: 5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            box-sizing: border-box;
            margin-left: 4vw;
            li {
                > span {
                    display: inline-block;
                    color: #ffffff;
                    line-height: 40px;
                    position: relative;
                    cursor: pointer;
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        margin: 5px 0 0 -8px;
                        width: 16px;
                        height: 3px;
                        background: transparent;
                        border-radius: 3px;
                    }
                    &.actived::after {
                        background-color: #fff;
                    }

                    &.router-link-exact-active::after {
                        background: #fff;
                    }
                }
                &:hover {
                    .subNavs {
                        display: block;
                    }
                }
            }
            .subArrow {
                position: relative;
            }
            .subArrow::after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                position: absolute;
                left: 50%;
                top: 1.8em;
                margin-left: -4px;
                border: 1px solid #fff;
                border-top: 0;
                border-radius: 2px;
                border-right: 0;
                transform: rotate(-45deg);
            }

            .subNavs {
                position: absolute;
                left: 50%;
                top: 40px;
                margin-left: -4vw;
                font-size: 15px;
                line-height: 20px;
                width: 8vw;
                padding: 1vw;
                background: rgba(0,0,0,.4);
                border-radius: 6px;
                text-align: center;
                display: none;
                span {
                    display: flex;
                    color: #ffffff;
                    margin-bottom: 10px;
                    font-size: 12px ;
                    cursor: pointer;
                    position: relative;
                    &:hover {
                        &::before {
                            content: '';
                            position: absolute;
                            left: -10px;
                            top: 2px;
                            border: 8px solid transparent;
                            border-left-color: #fff;
                        }
                    }
                    img {
                        width: 16px;
                        height: 16px;
                        float: left;
                    }
                    span {
                        margin-left: 5px;
                        font-size: 14px;
                    }
                    &::after {
                        content: "";
                        display: block;
                        clear: both;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .nav-other {
            flex: 2.5;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-left: 3%;
            font-size: 14px;
            position: relative;
            top: 1px;
            > button {
                box-sizing: border-box;
                cursor: pointer;
                color: #fff;
                background: none;
                border: 0;
                outline: none;
                font-family: Avenir, Helvetica, Arial, sans-serif;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            a {
                color: #fff;
                font-size: 14px;
            }
            .login {
                border-radius: 2vw;
                padding: .6vw 2vw;
                background: linear-gradient(to right, #F7B527, #FD8E39);
            }
            .icons {
                margin-left: 1.5vw;
                >a {
                    display: inline-block;
                    width: 1.56vw;
                    height: 1.56vw;
                    background: url("../assets/images/education/icon_home.png") no-repeat center center;
                    background-size: 100%;
                    /*&:last-child {*/
                    /*    background-image: url("../assets/images/education/icon_cart.png");*/
                    /*    margin-left: 1vw;*/
                    /*}*/
                }
            }
        }
    }

    .navH5{display: none;}

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        .navbar{display: none;}
        .navH5{
            display: block;
            position: absolute;
            top:0;
            left:0;
            z-index: 999;
            width: 100%;
            .logo{
                width: 180px;
                margin:10px auto 0;
            }
        }
    }
</style>