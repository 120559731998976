<template>
    <div :class="['bottom-nav', showBgColor ? 'bg-color' : '']">
        <div class="bottom-text" v-if="showScratchBg">
            <h1>丰富的教育服务资源<br/>轻松实现编程教学</h1>
            <p>线上线下教师编程培训，领取教学视频，教案，案例</p>
            <a href="https://edu.banbao.com:63398/" target="_blank" class="btn-more btn-join">立即参加</a>
        </div>
        <div :class="['bottom-container', showScratchBg ? 'show-bg' : '']">
            <div class="bottom">
                <div class="navs">
                    <div class="lf">
                        <div v-for="(item, index) in bottomNavs" :key="index" class="items" v-if="item.id !== 1">
                            <h2 @click="jumpTo(item)">{{ item.cnName }}</h2>
                            <ul>
                                <li
                                    v-for="(subItem, subIndex) in item.children"
                                    :key="index + '-' + subIndex"
                                    class="subItem"
                                >
                                    <span @click="jumpTo(subItem)">{{ subItem.cnName }}</span>
<!--                                    <router-link :to="subItem.templateUrl">{{ subItem.cnName }}</router-link>-->
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="rf">
                        <div class="logo">
                            <img :src="require('@/assets/images/education/logo-new.png')" alt />
                        </div>
                        <div class="tel">
                            <i class="icon-tel"></i>
                            课程咨询服务热线：
                            <h1>15875481030</h1>
                        </div>
                        <div class="qrcode">
                            <div>
                                <img :src="require('@/assets/images/education/qrcode_server.jpg')" alt />
                                <p>客服微信</p>
                            </div>
                            <div>
                                <img :src="require('@/assets/images/qrcode2.png')" alt />
                                <p>官方微信</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div>{{ seoBottom }}</div>-->
<!--            <div v-html="seoBottom" class="security-record">-->
            <div class="security-record">
                
                    <p>BanBao©,BanBao packaging©,TOBEES©,TOBEES design ©, Copyright 2020 www.banbao.com All Rights Reserved, Guangdong ICP prepare No. 2021091515</p>
                    <div>
                        <a href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44051102000411" target = "_blank">
                            <img :src="iconBadge" alt />
                            <span>粤公网安备：44051102000411号</span>
                        </a>
                        <a href = "https://beian.miit.gov.cn/" target = "_blank">
                            <img :src="iconBadge" alt />
                            <span>粤ICP备2021091515号</span>
                        </a>
                    </div>
                    <div class = "phone">
                        <a href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44051102000411" target = "_blank">
                            <img :src="iconBadge" alt />
                            <span>粤公网安备：44051102000411号</span>
                        </a>
                        <br />
                        <a href = "https://beian.miit.gov.cn/" target = "_blank">
                            <img :src="iconBadge" alt />
                            <span>粤ICP备2021091515号</span>
                        </a>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>

    const iconBadge = require("@/assets/images/icon_badge.png");
    import {urls} from '@/lib/common'
    import {mapState} from 'vuex';

    export default {
        props: {
            showScratchBg: {
                default: false,
                type: Boolean
            },
            showBgColor: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                iconBadge,
                bottomNavs: [],

                seoBottom: '',
            };
        },
        computed: {
            ...mapState(['lang']),
        },
        methods: {
            getNavs() {
                this.$http.defaults.headers.get['Web-Site'] = 2;
                this.$http.get(urls.getTopbarNavs).then(res => {
                    if (res.data.status === 200) {
                        this.bottomNavs = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getSeoSettings: function() {
                let that = this;

                that.$http({
                    url: `backServer/front/common/seo`,
                    method: 'get',
                    headers: {'Web-Site': 2}
                }).then((res) => {
                    if(res.data.status === 200) {
                        switch (that.lang) {
                            case 'zh':
                                that.seoBottom = res.data.data.cnFooter;
                                break;
                            case 'en':
                                that.seoBottom = res.data.data.enFooter;
                                break;
                        }
                    }
                });
            },
            jumpTo(item) {
                if (item.type === 2) {
                    window.open(item.templateUrl);
                } else {
                    this.$router.push({
                        path: item.templateUrl
                    })
                }
            }
        },
        mounted() {
            this.getNavs();
            this.getSeoSettings();
        }
    };
</script>

<style lang="less" scoped>
    .bottom-nav {
        &.bg-color {
            background-color: #F2FCFF;
        }
        .bottom-text {
            padding-bottom: 2vw;
            text-align: center;
            /*background-color: #F2FCFF;*/
            h1 {
                font-size: 36px;
                line-height: 150%;
                color: #0a0a0a;
                margin-bottom: 1vw;
            }
            p {
                font-size: 18px;
            }
            .btn-more {
                display: block;
                width: 166px;
                height: 52px;
                line-height: 52px;
                font-size: 16px;
                margin: 3vw auto 0;
                border-radius: 50px;
                text-align: center;
                background: linear-gradient(to right, #24CEFF, #1BB2FF);
                box-shadow: 0 2px 15px 4px rgba(27, 178, 255, 0.5);
                color: #fff;
                box-sizing: border-box;
            }
        }
        .bottom-container {
            position: relative;
            &.show-bg {
                padding-top: 10%;
                background-color: #F2FCFF;
                &::before {
                    content: '';
                    display: block;
                    width: 60vw;
                    height: 34vw;
                    background: url("../assets/images/education/bottom-bg.png") no-repeat center center;
                    background-size: 100%;
                    position:absolute;
                    left: 50%;
                    top: -2%;
                    margin-left: -30%;
                }
            }
            .bottom {
                width: 100%;
                background: url("../assets/images/education/bottom_bg.png") no-repeat 100% 100%;
                background-size: 100% 100%;
                color: #fff;
                padding: 14% 0 4%;
                display: flex;
                justify-content: space-between;
                position: relative;

                .navs {
                    width: 76%;
                    margin: 5vw auto 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .lf {
                        .items {
                            float: left;
                            margin-right: 3.125vw;
                            font-size: 18px;
                            h2 {
                                margin-bottom: 20px;
                                cursor: pointer;
                            }
                            .subItem {
                                line-height: 2.8vw;
                                span {
                                    color: #fff;
                                    font-size: 14px;
                                }
                            }
                        }
                        &::after {
                            content: "";
                            display: block;
                            clear: both;
                        }
                    }
                    .rf {
                        .logo {
                            width: 230px;
                        }
                        .tel {
                            font-size: 24px;
                            margin-top: 1.5vw;
                            color: #fff;
                            .icon-tel {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url("../assets/images/education/icon_tell.png") no-repeat
                                center center;
                                background-size: 100%;
                            }
                            h1 {
                                font-size: 34px;
                                color: #fff;
                            }
                        }
                        .qrcode {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            text-align: center;
                            margin-top: 1.5vw;
                            img {
                                width: 90px;
                                height: 90px;
                                margin-bottom: 0.192vw;
                            }
                            p{
                                font-size: 14px;
                                padding-top: 6px;
                            }
                        }
                    }
                }
            }
            .security-record {
                background: #0094E0;
                text-align: center;
                font-size: 14px;
                padding: 1.2vw;
                color: #fff;
                > div {
                    margin-top: 0.5vw;
                    a{
                        color:#fff;
                        &:hover{
                            text-decoration:underline;
                        }
                    }
                    img {
                        display: inline-block;
                        width: 0.83vw;
                        margin: 0 0.26vw 0 0.52vw;
                    }
                }
                .phone{display: none}
                
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        .bottom-nav{
            .bottom-text{
                display: none;
            }
            .bottom-container{
                &.show-bg{
                    padding-top: 0;
                }
                &.show-bg::before{
                    display: none;
                }
                .bottom{
                    background:url('../assets/images/phone/login_bg2.png') no-repeat center top;
                    background-size: 100% auto;
                    .navs{
                        width: 60%;
                        margin-top:10%;
                        .lf{
                            display: none;
                        }
                        .rf{
                            .logo{
                                width: 100%;
                                padding: 2%;
                            }
                            .tel{
                                font-family: YouSheBiaoTiHei;
                                color: #fff;
                                .fontFn(20);
                                .icon-tel{
                                    .fontFn(22,width);
                                    .fontFn(22,height);
                                    position: relative;
                                    top:4px;
                                }
                                h1{
                                    .fontFn(32);
                                    padding-top: 6%;
                                    padding-bottom: 8%;
                                    white-space: nowrap;
                                }
                            }
                            .qrcode{
                                padding:0 8% 4%;
                                img{
                                    .fontFn(66,width);
                                    .fontFn(66,height);
                                }
                                p{
                                    .fontFn(10);
                                    padding-top: 12%;
                                }
                            }
                        }
                    }
                }
                .security-record{
                    margin-top:-2px;
                    background: linear-gradient(#19A5EC 0%, #39B1EE 100%);
                    
                    p{
                        .fontFn(12);
                        .fontFn(24,line-height);
                        color:rgba(255,255,255,0.8)
                        ;margin:0 auto;
                        .fontFn(344,max-width);
                    }
                    >div{
                        display: none;
                        
                    }
                    .phone{
                        display: block;
                        padding-bottom: 4%;
                        img{
                            display: inline-block;
                            vertical-align: middle;
                            .fontFn(16,width);
                            .fontFn(16,height);
                            .fontFn(6,margin-right);
                            .fontFn(8,margin-bottom);
                        }
                        span{
                            display: inline-block;
                            vertical-align: middle;
                            .fontFn(12);
                            .fontFn(10,margin-bottom);
                            color:rgba(255,255,255,0.8);
                        }
                    }
                }
                        
                
            }
        }
    }
</style>